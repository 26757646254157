define("shared/mixins/crud-catalog", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Mixin.create({
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    router: Ember.inject.service(),
    app: null,
    appName: null,
    nsName: null,
    appVersion: null,
    cluster: null,
    project: null,
    timeOutAnchor: null,
    enabled: false,
    ready: false,
    saved: false,
    confirmDisable: false,
    forceUpgrade: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        enabled: !!Ember.get(this, 'app') && Ember.get(this, 'app.state') !== 'removing',
        ready: !!Ember.get(this, 'app') && _constants.default.ACTIVEISH_STATES.includes(Ember.get(this, 'app.state'))
      });
      this.startAppStatusChecker();
    },
    actions: {
      disable: function disable() {
        var _this = this;

        var url = Ember.get(this, 'app.links.self');
        Ember.get(this, 'globalStore').rawRequest({
          url: url,
          method: 'DELETE'
        }).then(function () {
          setTimeout(function () {
            window.location.href = window.location.href; // eslint-disable-line no-self-assign
          }, 1000);
        }).catch(function (err) {
          _this.showError(err);
        });
      },
      promptDisable: function promptDisable() {
        Ember.set(this, 'confirmDisable', true);
        Ember.run.later(this, function () {
          Ember.set(this, 'confirmDisable', false);
        }, 10000);
      }
    },
    startAppStatusChecker: function startAppStatusChecker() {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var timeOutAnchor = setTimeout(function () {
        _this2.queryStatus();
      }, 30000);
      Ember.set(this, 'timeOutAnchor', timeOutAnchor);
    },
    queryStatus: function queryStatus() {
      var _this3 = this;

      var url = Ember.get(this, 'app.links.self');

      if (url) {
        Ember.get(this, 'globalStore').rawRequest({
          url: url,
          method: 'GET'
        }).then(function (res) {
          var app = Ember.get(res, 'body');
          Ember.setProperties(_this3, {
            enabled: Ember.get(app, 'state') !== 'removing',
            ready: _constants.default.ACTIVEISH_STATES.includes(Ember.get(app, 'state')),
            app: Ember.get(_this3, 'globalStore').createRecord(_objectSpread({
              type: 'app'
            }, app))
          });
        }).finally(function () {
          _this3.startAppStatusChecker();
        });
      } else {
        Ember.set(this, 'ready', false);
      }
    },
    save: function save(cb, answers) {
      var refresh = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var customAnswers = Ember.get(this, 'customAnswers') || {};
      Object.keys(customAnswers).forEach(function (key) {
        answers[key] = customAnswers[key];
      });
      Ember.set(this, 'answers', answers);

      if (Ember.get(this, 'enabled')) {
        this.update(cb);

        if (Ember.get(this, 'actions.upgrade')) {
          this.send('upgrade');
        }
      } else {
        this.create(cb);
      }

      if (refresh) {
        this.refresh();
      }
    },
    update: function update(cb) {
      var _this4 = this;

      var templateVersion = this.templateVersion,
          app = this.app;
      var externalIdInfo = app.externalIdInfo;
      var currentVersion = Ember.get(this, 'apps.firstObject.externalIdInfo.version');

      if (!templateVersion || !currentVersion || templateVersion === currentVersion) {
        return cb(false);
      }

      var data = {
        answers: Ember.get(this, 'answers'),
        externalId: Ember.get(app, 'externalId').replace("version=".concat(Ember.get(externalIdInfo, 'version')), "version=".concat(templateVersion))
      };

      if (this.forceUpgrade) {
        Ember.set(data, 'forceUpgrade', true);
      }

      Ember.get(this, 'globalStore').rawRequest({
        url: Ember.get(this, 'app.actionLinks.upgrade'),
        method: 'POST',
        data: data
      }).then(function () {
        Ember.set(_this4, 'saved', true);
      }).catch(function (err) {
        _this4.showError(err);
      }).finally(function () {
        cb();
      });
    },
    create: function create(cb) {
      var _this5 = this;

      var promise;

      if (Ember.get(this, 'nsExists')) {
        if (Ember.get(this, 'nsNeedMove')) {
          promise = this.moveNamespace(cb);
        } else {
          promise = Ember.RSVP.resolve();
        }
      } else {
        promise = this.createNamespace(cb);
      }

      promise.then(function () {
        Ember.get(_this5, 'globalStore').rawRequest({
          url: "/v3/projects/".concat(Ember.get(_this5, 'project.id'), "/app"),
          method: 'POST',
          data: {
            answers: Ember.get(_this5, 'answers'),
            externalId: Ember.get(_this5, 'appVersion'),
            name: Ember.get(_this5, 'appName'),
            projectId: Ember.get(_this5, 'project.id'),
            targetNamespace: Ember.get(_this5, 'nsName')
          }
        }).then(function (res) {
          Ember.setProperties(_this5, {
            enabled: true,
            app: Ember.get(_this5, 'globalStore').createRecord(_objectSpread({}, res.body, {
              type: 'app'
            }))
          });
          Ember.set(_this5, 'saved', true);

          if (_this5.doneSaving) {
            _this5.doneSaving();
          }
        }).catch(function (err) {
          _this5.showError(err);
        }).finally(function () {
          cb();
        });
      });
    },
    createNamespace: function createNamespace(cb) {
      var _this6 = this;

      return Ember.get(this, 'globalStore').rawRequest({
        url: "/v3/clusters/".concat(Ember.get(this, 'cluster.id'), "/namespace"),
        method: 'POST',
        data: {
          name: Ember.get(this, 'nsName'),
          projectId: Ember.get(this, 'project.id')
        }
      }).catch(function (err) {
        _this6.showError(err);

        cb();
      });
    },
    moveNamespace: function moveNamespace(cb) {
      var _this7 = this;

      return Ember.get(this, 'globalStore').rawRequest({
        url: "/v3/clusters/".concat(Ember.get(this, 'cluster.id'), "/namespace/").concat(Ember.get(this, 'namespace.id'), "?action=move"),
        method: 'POST',
        data: {
          projectId: Ember.get(this, 'project.id')
        }
      }).catch(function (err) {
        _this7.showError(err);

        cb();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.clearTimeOut();

      this._super();
    },
    clearTimeOut: function clearTimeOut() {
      var timeOutAnchor = Ember.get(this, 'timeOutAnchor');

      if (timeOutAnchor) {
        clearTimeout(timeOutAnchor);
        Ember.set(this, 'timeOutAnchor', timeOutAnchor);
      }
    },
    refresh: function refresh() {
      var currentRouteName = Ember.get(this, 'router.currentRouteName');
      var routeAddons = ['global-admin.'];
      var routeName = currentRouteName;
      routeAddons.map(function (r) {
        if (currentRouteName.startsWith(r)) {
          routeName = currentRouteName.replace(r, '');
        }
      });
      var currentRouteInstance = Ember.getOwner(this).lookup("route:".concat(routeName));
      currentRouteInstance.send('refresh');
    },
    showError: function showError(err) {
      Ember.get(this, 'growl').fromError('Error', Ember.get(err, 'body.message'));
    }
  });

  _exports.default = _default;
});